import React, { useEffect, useState,useMemo } from 'react'
import VideoJS from './player'
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { useHistory } from 'react-router-dom';
import left_arrow from "../assets/regal_images/left-arrow.png"
import logo from '../assets/regal_images/regal_logo.png'

import { useDispatch,useSelector } from 'react-redux';
import axios from "axios";
import { Box,Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { RUNNING_STATUS } from '../Actiontype/ActionType';
import { change_running_status } from '../actions/HomeActions';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

export default function StreamFile() {

  const playerRef = React.useRef(null);
  const history=useHistory()
  const dispatch=useDispatch()
  const location = useLocation()
  const [streamData, setStreamData] = useState(location?.state?.stream)
  const running_status= useSelector((state)=>state.home.running_status)
  const [popupOpen, setPopupOpen] = useState(false)
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

  useEffect(() => {
    (async () => {
      if (location?.state) {
        setStreamData(location?.state?.stream)
        const res = await axios.get("https://api.ipify.org/?format=json");
    dispatch(change_running_status({user:user_id,login_type:"Web",running_status:"Running",ip_address: res.data.ip}))
      }
    })()
  }, [])


  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    textTrackSettings: false,
   
    userActions: { hotkeys: true },
    controlBar: {
      fullscreenToggle: true,
      volumePanel: {inline: false},
      pictureInPictureToggle: false,
      audioTrackButton:false

    },


    

    sources: [
      {
        src: `${streamData?.data?.channel_live_url}`,
        type: 'application/x-mpegURL'
      }
    ]
  };


  const handlePlayerReady = (player) => {
  
    playerRef.current = player;
    player.controlBar.removeChild("liveDisplay")
    // player.addChild("liveDisplay")
    // player.titleBar.removeClass('vjs-hidden')
    // player.titleBar.addChild('button', {
    //   controlText: `${streamData?.data?.channel_name}`,
    //   className: 'vjs-visible-text',
    //   position: 2,
    // });

    
    player.addChild('button', {
      className: 'go-back',
      clickHandler: function(event) {
        history.goBack()
      }
    });
    
    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
      (async () => {
        if (running_status?.statuscode==200) {
          const res = await axios.get("https://api.ipify.org/?format=json");
      dispatch(change_running_status({user:user_id,login_type:"Web",running_status:"Stop",ip_address: res.data.ip}))
        }
      })()
    
    });
    
    player.on("userinactive",function(){
      document.getElementsByClassName("vjs-quality-selector")[0].style.display="none"
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display="none"
      document.getElementsByClassName(" go-back")[0].style.display="none"

      
     
    })
    player.on("useractive",function(){
      document.getElementsByClassName("vjs-quality-selector")[0].style.display="block"
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display="block"
      document.getElementsByClassName(" go-back")[0].style.display="block"
    })
    
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
    history.goBack()
    dispatch({type:RUNNING_STATUS,payload:null})
};

  useMemo(()=>{
    if(running_status?.statuscode==406)
    {
      setPopupOpen(true)
      
    
      
    }
      },[running_status])
  return (
    <><Modal
    open={popupOpen}
    onClose={handlePopupClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
   
        <Box sx={style}>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} width={"100px"} />
            </div>
            <hr />

            <div className="login-popup" >
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                    {running_status?.message}
                </Typography>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    
                    <button onClick={handlePopupClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                        Close
                    </button>
                </div>
            </div>
        </Box>
</Modal>
{!popupOpen&& <div style={{maxHeight:"100vh",overflow:"hidden"}}>
    <div className='video-parent stream live-tv'>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </div>

  </div>}
  </>
  )
}
