import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as HomeActions from "../../../actions/HomeActions";
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}

function Slider(props) {
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))

    const history = useHistory()
    SwiperCore.use([Autoplay])
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [slug, setSlug] = useState("")
    const [watchListopen, setWatchlistOpen] = React.useState(false);
    const [popupOpen, setPopupOpen] = useState(false)

    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    // useEffect(() => {
    //     (async () => {
    //         const sliderRes = await getSliderData()
    //         setSliderData(sliderRes?.data)
    //     })();
    // }, [])

    const handleClick = (event, slug) => {
        setAnchorEl(event.currentTarget);
        setSlug(slug)
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    const AddWatchList = () => {
        setWatchlistOpen(true);
    };

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setWatchlistOpen(false);
    };
    const handlePlay = (data) => {
        //     if(data?.stream?.stream_category_name=="NEWS CHANNELS")
        //     history.push(ROUTES.stream, { stream: {data:data?.stream} })
        // else
        // if (!isLoggedIn)
        //     setPopupOpen(true)
        // else
        //     {
                history.push(`/live-streaming-details/${data?.stream?.slug}`)

            // }
    };

    return (
        <>
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Login to continue
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box>
            </Modal>
            <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    navigation={{
                        prevEl: '#prev5',
                        nextEl: '#next5'
                    }}

                    pagination={{
                        "clickable": true
                    }}

                    autoplay={{
                        delay: 4000,
                    }}
                    onInit={() => { animationInit() }}
                    onSlideChangeTransitionStart={() => animationInit()}
                    loop={true}
                    id="home-slider"
                    className="slider m-0 p-0">
                    {
                        props?.Sliderdata?.data?.map((data, index) => (
                            <SwiperSlide
                                className="slide slick-bg"
                                key={index}
                                style={{ backgroundImage: `url(${IMAGE_ENDPOINT + data?.web_banner})` }}

                            >
                                <Container fluid className="position-relative h-100">
                                    <div className="slider-inner h-100">
                                        <Row className="align-items-center  iq-ltr-direction h-100 ">
                                            <Col xl="6" lg="12" md="12">
                                                <div style={{ cursor: "pointer" }} onClick={() => history.push(`/live-streaming-details/${data?.stream?.slug}`)}>
                                                    <div className="channel-logo" data-iq-delay="0.5">
                                                        <h2>{data?.stream?.channel_name}</h2>
                                                        {/* <img src={`${IMAGE_ENDPOINT + data?.stream?.thumbnail}`} className="c-logo" alt="streamit" /> */}
                                                    </div>
                                                </div>
                                                {/* <Link to={`/live-streaming-details/${data?.stream?.slug}`}>
                                                    <h2 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{data?.stream?.movie_name}</h2>
                                                </Link> */}
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <div style={{ cursor: "pointer" }} onClick={() => {

                                                        history.push(`/live-streaming-details/${data?.stream?.slug}`)

                                                    }}>
                                                        <div className="d-flex flex-wrap align-items-center">
                                                            <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" dangerouslySetInnerHTML={{ __html: data?.stream?.description }}>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                        <p>
                                                            <b>Streaming Date: </b><span>{data?.stream?.streaming_date}</span>
                                                        </p>
                                                     
                                                    </div> */}

                                                </div>

                                                <div className="d-flex align-items-center r-mb-23 mt-3" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                                                    <div onClick={() => {

                                                        handlePlay(data)

                                                    }
                                                    } className="btn btn-hover iq-button">
                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Stream Now
                                                    </div>
                                                    <Link to={`/live-streaming-details/${data?.stream?.slug}`} >
                                                        {/* <i className="fa fa-share icon-border ml-2"></i> */}
                                                        <div className='icon-border-details ml-1'>
                                                            <i className="fa fa-info details-con" aria-hidden="true"></i>  Details

                                                        </div>
                                                    </Link>
                                                    {/* <div onClick={AddWatchList}>
                                                        <i className="fa fa-heart ml-3 fill-color" aria-hidden="true"></i>
                                                    </div> */}

                                                    <div onClick={(event) => handleClick(event, data?.stream?.slug)}>
                                                        <i className="fa fa-share-alt share-icon ml-3" aria-hidden="true"></i>
                                                    </div>



                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        {/* {data?.stream?.slug} */}
                                                        <Typography sx={{ p: 2 }}>
                                                            <Share sharedURL={`${window.location.href}/${slug}`} />
                                                        </Typography>
                                                    </Popover>



                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Container>
                            </SwiperSlide>
                        ))
                    }


                </Swiper>
            </section>
            <Snackbar open={watchListopen} autoHideDuration={3000} onClose={handleCloseMessage}>
                <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }}>
                    Movie Added to Watchlist.
                </Alert>
            </Snackbar>
        </>
    )
}
export default Slider