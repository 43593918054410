import React, { useEffect, useMemo, useState } from 'react'
import { ROUTES } from '../constant/routes'
import { Col, Container, Row } from 'react-bootstrap'
import rewardImg from '../assets/regal_images/rewards.png'
import coinImg from '../assets/regal_images/coin-img.png'
import ListTable from '../views/backend/pages/auth/table'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as MovieAction from '../actions/MovieAction'
import aboutus1 from '../assets/images/about-us/01.jpg'
import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

const Rewards = () => {
    const [faq, setfaq] = useState('1')

    const user_id = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    const { getCoinDetails } = bindActionCreators(MovieAction, dispatch)
    const coinDetail = useSelector((state) => state?.movies?.coin)
    const [coinHistory, setCoinHistory] = useState([])
    const [expanded, setExpanded] = React.useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        (async () => {
            const resData = await getCoinDetails({ user_id: user_id?.id })
            setCoinHistory(resData)
        })()
    }, [])




    var tableData = {
        tableTitle: "Coin History",
        setSearch: false,

        tableHead: [
            {
                id: "coin_given_history",
                label: "Coin",
            },
            {
                id: "movie_name",
                label: "Movie",
            },
            {
                id: "plan_name",
                label: "Plan",
            },
            {
                id: "coin_redeem_date",
                label: "Date",
            },

        ],
        tableBody: [
            // {
            //     id: 0,
            //     slider_type: "Landscape",
            //     app_banner: "Landscape",
            //     web_banner: "Landscape",
            //     videoType: "Landscape",
            //     set_sequence: "2",
            //     status: "Active"
            // },
        ],

    };
    console.log("coinDetail", coinDetail)
    useMemo(() => {
        tableData["tableBody"] = coinDetail && coinDetail?.data?.map((ele) => ({
            ...ele,
            // coin_given_history: Number(ele?.coin_given_history),
            coin_given_history: (
                <span>
                    <img style={{ width: "20px" }} src={coinImg} className="ml-1" />{/* Replace "your-icon-class" with your actual icon class */}
                    {Number(ele?.coin_given_history)}
                </span>
            ),
            movie_name: ele?.movie_name || '--',
            plan_name: ele?.plan_name || "--",
            date: ele?.coin_redeem_date
        })) || []
    }, [coinHistory, coinDetail])
    return (

        <section className="m-profile setting-wrapper  set-margin-responsive1">
            <Container>
                <Row style={{ alignItems: "center" }}>
                    <Col lg="4" className="">
                        <h5 className="main-title mb-4">Coin History</h5>

                        <div className="sign-user_card text-center">
                            <img style={{ height: "150px" }} src={rewardImg} className="rounded-circle img-fluid d-block mx-auto mb-3" alt="user" />
                            <h6>Available coins :</h6>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                <img src={coinImg} alt='coin' style={{ width: "30px" }} />
                                <h4 className="pl-2">{coinHistory?.user_coin || 0} Coins</h4>

                            </div>
                        </div>
                    </Col>
                    <Col lg="8">
                        {/* <h5 className="main-title">Points History</h5> */}
                        <ListTable
                            tableData={tableData}
                            key={"ListTable"}
                        />
                    </Col>
                </Row>
            </Container>
            <Grid className='mt-3' container spacing={2} style={{ display: "flex", justifyContent: "center", padding: "10px", width: "100%", margin: "auto" }}>
                <Grid item xs={12} md={8} >
                    <Container>
                        <h5 className='pt-2'>Frequently Asked Questions</h5>
                        <Accordion
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                border: "1px solid grey"
                            }}
                            className='mt-3'
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>What are GS-Coins?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                GS-Coins are the virtual currency used within GUJJU STREAM platform.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                border: "1px solid grey"
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>What can GS-Coins be used for?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    GS-Coins can be utilized for various purposes such as:<br />

                                    Purchasing TVOD (Transactional Video on Demand) content.<br />
                                    Subscribing to premium content.<br />
                                    Availing offers from merchants.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                border: "1px solid grey"
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>What is the value of GS-Coins?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    The value of GS-Coins is as follows:<br />

                                    1 GS-Coin = $0.0015 AUD<br />
                                    10 GS-Coins = $0.015 AUD<br />
                                    100 GS-Coins = $0.15 AUD<br />
                                    250 GS-Coins = $0.38 AUD<br />
                                    500 GS-Coins = $0.77 AUD<br />
                                    1,000 GS-Coins = $1.5 AUD<br />
                                    2,500 GS-Coins = $2.50 AUD<br />
                                    5,000 GS-Coins = $5.00 AUD (PAYOUT ELIGIBLE AMOUNT)<br />
                                    10,000 GS-Coins = $10.00 AUD (PAYOUT ELIGIBLE AMOUNT)<br />
                                    20,000 GS-Coins = $20.00 AUD (PAYOUT ELIGIBLE AMOUNT)<br />
                                    30,000 GS-Coins = $30.00 AUD (PAYOUT ELIGIBLE AMOUNT)<br />
                                    40,000 GS-Coins = $40.00 AUD (PAYOUT ELIGIBLE AMOUNT)
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                border: "1px solid grey"
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>How do I access the GS-Coin Payout page?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    The GS-Coin Payout page can be accessed from the user profile page. This page provides information such as the GS-Coin count, A-Coin payout meter, transaction history, etc.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                border: "1px solid grey"
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>Can I convert GS-Coins into other currencies?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Currently, GS-Coins are valued in AUD (Australian Dollar) and are used within the GUJJU STREAM platform.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Container>
                </Grid>
            </Grid>

        </section>
    )
}

export default Rewards