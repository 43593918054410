import React from 'react'
import Qr_detail from './Qr_detail'
import { useState } from 'react'
import Navigation_bar from './Navigation_bar'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useEffect } from 'react'
import * as Movieaction from '../actions/MovieAction'


const Qr_layout = () => {


    const [currentpage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(4)




    const Lastpostindex = currentpage * postsPerPage;
    const firstpostindex = Lastpostindex - postsPerPage;



    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const { userqrredeemhistory } = bindActionCreators(Movieaction, dispatch)
    const [redeemdetail, setRedeemDetail] = useState([])
    const [totalpost, setTotalPost] = useState()



    useEffect(() => {
        (async () => {
            let user = {
                user_id: user_id ? user_id : "null",
            }
            const resData = await userqrredeemhistory(user)
            setRedeemDetail(resData?.data?.slice(Number(firstpostindex), Number(Lastpostindex)))
            setTotalPost(resData?.data?.length)
            // console.log("yyyyyyyyyyyyyy", Lastpostindex)
        })();
    }, [currentpage])


    // console.log("log", currentpage)





    return (
        <div>
            <Qr_detail Lastpostindex={Lastpostindex} firstpostindex={firstpostindex} redeemdetail={redeemdetail} />
            <Navigation_bar
                totalpost={totalpost}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentpage={currentpage}
            />
        </div>
    )
}

export default Qr_layout