import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import gujjuCoin from '../../../assets/regal_images/coin.png'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export default function Category(props) {
    return (
        <>
            {
                props?.category?.data?.length ?
                    <div className='main-content'>
                        <section id="iq-favorites">
                            <Container fluid>
                                <Row>
                                    <Col sm="12" className="overflow-hidden">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {/* <img src={design} className='mr-2' alt='design' style={{width:"10%"}}/> */}
                                                {/* <h6 className={"main-title " + (props?.category?.name == "Recently Launched" && "btn-color")} style={{ textTransform: "uppercase" }}>{props?.category?.name || "Loading"}</h6> */}
                                                <h6 className={"main-title "} style={{ textTransform: "uppercase" }}>{props?.category?.name || "Loading"}</h6>

                                                {/* <img src={design} className="ml-2" alt='design' style={{width:"10%"}}/> */}

                                            </div>
                                            {/* <h6 className={"main-title "+(props?.category?.name=="Recently Launched"&&"btn btn-hover iq-button")} style={{ textTransform: "uppercase" }}>{ props?.category?.name|| "Loading"}</h6> */}

                                        </div>
                                        <div id="favorites-contens">
                                            {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                        <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                            <Swiper
                                                navigation={{
                                                    prevEl: '#prev',
                                                    nextEl: '#next'
                                                }}
                                                breakpoints={{
                                                    320: { slidesPerView: 2, spaceBetween: 12 },
                                                    450: { slidesPerView: 3 },
                                                    638: { slidesPerView: 3 },
                                                    810: { slidesPerView: 4 },
                                                    1048: { slidesPerView: 5 },
                                                    1300: { slidesPerView: 6 },
                                                    1600: { slidesPerView: 7, spaceBetween: 9 },
                                                    1650: { slidesPerView: 8 },
                                                    2050: { slidesPerView: 9 },
                                                    2230: { slidesPerView: 10 },
                                                    2415: { slidesPerView: 11 },
                                                }}
                                                // loop={(movies?.length > 4) ? true : false}
                                                loop={false}
                                                // slidesPerView={8}
                                                spaceBetween={12}
                                                as="ul"
                                                id={'slider-' + props?.category?.name}
                                                onTransitionEnd={(slider) => {
                                                    var view_all = document.getElementById("view-all-" + props?.category?.name)
                                                    if ((slider?.virtualSize + slider?.translate) == slider?.size) {
                                                        view_all.style.display = "block"
                                                    }
                                                    else {
                                                        view_all.style.display = "none"

                                                    }
                                                }}
                                                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction  ttemp">
                                                {
                                                    props?.category?.data?.length ?

                                                        props?.category?.data?.map((data, index) => (
                                                            <SwiperSlide as="li" key={index}>
                                                                <div className="thumbnail-container">
                                                                    <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                                                        <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className={`thumb-img1`} alt={data?.movie_name} />
                                                                        {
                                                                            data?.leaving_soon === "true" && <div className='leaving-soon'>
                                                                                Leaving soon
                                                                            </div>
                                                                        }
                                                                    </Link>
                                                                    <div className='hover-class1'>
                                                                        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                                            <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} />
                                                                            <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                                        </div>
                                                                        <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                                            {data.movie_name}
                                                                        </div>
                                                                        {/* <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                                            <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                                                            {" " + data?.movie_coin} Coins
                                                                        </div> */}
                                                                        <span style={{ color: "white", fontSize: "0.8rem", marginTop: "8px" }}>Duration : {data?.movie_duration}
                                                                        </span>
                                                                        <span style={{ color: "white", fontSize: "0.8rem", marginTop: "1px" }}> Genre : {data?.movie_genre[0]?.genre_title}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>


                                                        ))

                                                        :
                                                        <></>
                                                }

                                            </Swiper>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </div>
                    :
                    <></>
            }

        </>
    )
}
