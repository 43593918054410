import HomeIcon from '@mui/icons-material/Home';
import MovieIcon from '@mui/icons-material/Movie';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useState } from 'react';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import logo from '../../../../assets/regal_images/regal_logo.png';

import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import SmsIcon from '@mui/icons-material/Sms';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ROUTES } from '../../../../constant/routes'
import { NavLink, Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';

import CustomToggle from '../../../../components/dropdowns'
import { useDispatch } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { logout } from '../../../../actions/HomeActions';
import axios from 'axios';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AlertMessage from '../../../../views/backend/common/AlertMessage';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';




const Headerstyle2 = () => {


    var isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false);
    const [ip, setIP] = useState("")
    const [searchopen, setSearchOpen] = useState(false)
    const toggleSidebar = () => setIsOpen(!isOpen);
    const [logoutdialog, setLogoutDialogue] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [form, setForm] = useState({ user: user_id, user_id: user_id })
    const [loginAlert, setLoginAlert] = useState(false)
    const history = useHistory()
    const handleClose = () => {
        setLogoutDialogue(false)
        setLoginAlert(false)
    }

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
        setForm({ ...form, ip_address: res.data.ip, user_id: user_id });
    };
    useEffect(() => {
        getData();
    }, [isLoggedIn]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: "100vh",
        width: 400,
        bgcolor: 'rgb(0,0,0,0.9)',
        boxShadow: 24,
        borderRadius: "10px",
        p: 4,
    };

    return (
        <>
            {/* <AlertMessage/> */}

            <div className='bottom-header class-bottom-nav'>


                <div className='bottom-header-1 black-background'>
                    <Link className={window?.location?.pathname == ROUTES.home ? "homelink" : "none"} to={ROUTES.home} style={{ padding: "0" }}>
                        <div className='bottom-header-icon-section'>
                            <HomeIcon className='bottom-header-icon' />
                            <p style={{ margin: "0" }}>Home</p>
                        </div>
                    </Link>
                    <NavLink to={ROUTES.search} style={{ padding: "0" }}>
                        <div onClick={() => setSearchOpen(true)} className='bottom-header-icon-section' >
                            <SearchIcon className='bottom-header-icon' />
                            <p style={{ margin: "0" }}>Search</p>
                        </div>
                    </NavLink>
                    <NavLink to={ROUTES.movies} style={{ padding: "0" }}>
                        <div className='bottom-header-icon-section'>
                            <MovieIcon className='bottom-header-icon' />
                            <p style={{ margin: "0" }}>Movies</p>
                        </div>
                    </NavLink>
                    {/* <NavLink to={ROUTES.shortSeries} style={{ padding: "0" }}>
                        <div className='bottom-header-icon-section'>
                            <LocalMoviesIcon className='bottom-header-icon' />
                            <p style={{ margin: "0" }}>Short Series</p>
                        </div>
                    </NavLink> */}
                    <NavLink to={ROUTES.series} style={{ padding: "0" }}>
                        <div className='bottom-header-icon-section' >
                            <PlayCircleFilledWhiteIcon className='bottom-header-icon' />
                            <p style={{ margin: "0" }}>Web Series</p>
                        </div>
                    </NavLink>
                    {/* <NavLink to={ROUTES.vod} style={{ padding: "0" }}>
                        <div className='bottom-header-icon-section' >
                            <LiveTvIcon className='bottom-header-icon' />
                            <p style={{ margin: "0" }}>VOD</p>
                        </div>
                    </NavLink> */}
                    <div onClick={toggleSidebar} className='bottom-header-icon-section'>
                        <AccountCircleIcon className='bottom-header-icon' />
                        <p style={{ margin: "0" }}>Profile</p>
                    </div>

                </div>
            </div>

            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className='sidebar-section-1'>
                    <img height={100} width={100} src={logo} alt="" />
                    <i onClick={toggleSidebar} style={{ color: "black", padding: "4px 6px", backgroundColor: "white", fontSize: "1.1rem", borderRadius: "11px" }} className="fa fa-times" aria-hidden="true"></i>
                </div>

                <hr style={{ paddingBottom: "0.7rem" }} />

                <div className='sidebar-section-2'>
                    <h5 style={{ fontSize: "1.25rem", fontWeight: "600", letterSpacing: "1.5px" }}>You <ArrowForwardIosIcon style={{ marginLeft: "5px", fontSize: "1.1rem" }} /> </h5>
                    {!isLoggedIn && <NavLink to="/login">
                        <div className='sidebar-section-2_1'>
                            <LoginIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>Login / Register</p>
                        </div>
                    </NavLink>
                    }

                    <div onClick={() => isLoggedIn ? history.push(ROUTES.my_account) : setLoginAlert(true)}>
                        <div className='sidebar-section-2_1'>
                            <PersonIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>My Account</p>
                        </div>
                    </div>
                    {/* <div onClick={() => isLoggedIn ? history.push(ROUTES.rewards) : setLoginAlert(true)}>
                        <div className='sidebar-section-2_1'>
                            <CardGiftcardIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>Offers and Rewards</p>
                        </div>
                    </div> */}

                    <div onClick={() => isLoggedIn ? history.push(ROUTES.watchlist) : setLoginAlert(true)}>
                        <div className='sidebar-section-2_1'>
                            <QueuePlayNextIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>Watch later</p>
                        </div>
                    </div>
                    <div onClick={() => isLoggedIn ? history.push(ROUTES.complaint) : setLoginAlert(true)}>
                        <div className='sidebar-section-2_1'>
                            <SmsIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>Help & Support</p>
                        </div>
                    </div>
                    {/* <div onClick={() => isLoggedIn ? history.push(ROUTES.demandMovie) : setLoginAlert(true)}>
                        <div className='sidebar-section-2_1'>
                            <ContentPasteGoIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>You Demand, We Deliver</p>
                        </div>
                    </div> */}
                    {isLoggedIn && <div
                        onClick={() => setLogoutDialogue(true)}>
                        <div className='sidebar-section-2_1'>
                            <LogoutIcon style={{ fontSize: "1.82rem" }} />
                            <p style={{ margin: "0" }}>Log Out</p>
                        </div>
                    </div>}
                    {/* <NavLink to={ROUTES.priceplan} className="btn btn-hover iq-button sub-btn" style={{ padding: "10px" }}>
                        <span onClick={() => { setIsOpen(false) }} className='ml-2 mr-2'>Subscribe</span>
                    </NavLink> */}
                </div>
            </div>

            <Modal
                open={logoutdialog}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                    </div>
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to logout?
                    </Typography>
                    <div className='pt-3' style={{ display: "flex", justifyContent: "flex-end" }}>
                        <NavLink to="/">
                            <button className="btn btn-hover iq-button" onClick={() => { dispatch(logout(form)); setLogoutDialogue(false); setIsOpen(false) }}>
                                Yes
                            </button>
                        </NavLink>
                        <button onClick={handleClose} className="btn btn-hover iq-button ml-2">
                            No
                        </button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={loginAlert}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                    </div>

                    <hr />

                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                        Please login to continue.
                    </Typography>
                    <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                        Login
                    </button>
                </Box>
            </Modal>


        </>
    );
}

export default Headerstyle2;
