import axios from "axios";
import { GET_EPISODE_DETAILS_API, GET_SERIES_DETAILS_API, LIKED_SERIES_API } from "../constant/ApiList";
import { GET_EPISODE_DETAILS, GET_SERIES_DETAILS, GET_SERIES_DETAILS_ERROR } from "../Actiontype/ActionType";
import { Base64 } from "js-base64";

export const getSeriesDetails = (slug, userObj) => {
    return async (dispatch) => {
        return await axios
            .post(`${GET_SERIES_DETAILS_API}/${slug}/`, userObj)
            .then((res) => {
                dispatch({
                    type: GET_SERIES_DETAILS,
                    payload: res.data
                });
                console.log("sasadad",res)
                return JSON.parse(Base64.decode(res?.data));
            }).catch((error) => {
                dispatch({
                    type: GET_SERIES_DETAILS_ERROR
                });
                return error.response;
            })
    }
}

export const addToLikedSeries = async (userObj) => {
    return await axios
        .post(LIKED_SERIES_API, userObj)
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}


export const getEpisodeDetails = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(`${GET_EPISODE_DETAILS_API}`, userObj)
            .then((res) => {
                dispatch({
                    type: GET_EPISODE_DETAILS,
                    payload: res.data
                });
                return res.data;
            }).catch((error) => {
                dispatch({
                    type: GET_SERIES_DETAILS_ERROR
                });
                return error.response;
            })
    }
}