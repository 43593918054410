import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as MoviesStore from '../../../../actions/MovieAction'
import { Card, Container, Dropdown, DropdownButton } from 'react-bootstrap'
import { ROUTES } from '../../../../constant/routes'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import empty from '../../../../assets/regal_images/empty-box (1).png'
import rent_icon from '../../../../assets/regal_images/Rent (1).png'
import * as HomeAction from '../../../../actions/HomeActions'
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { useSelector } from 'react-redux';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import gujjuCoin from '../../../../assets/regal_images/coin.png'


export default function MoviebyGenre() {
  const location = useLocation()
  const [movies, setMovies] = useState([])
  const dispatch = useDispatch()
  const { getAllMoviesByGenre } = bindActionCreators(MoviesStore, dispatch)
  const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)

  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const [homedata, setHomeData] = useState([])
  const [home, setHome] = useState([])

  const languageData = useSelector((state) => state?.movies?.language?.language_list)

  const history = useHistory()
  useEffect(() => {
    (async () => {
      // const resData = await getAllMoviesByGenre()
      if (location?.state?.id) {
        const formData = new FormData()
        formData.append("id", location?.state?.id)
        const resData = await getAllMoviesByGenre(formData)
        setMovies(resData)
        let userObj = {
          user_id: user_id ? user_id : "null",
          profile_id: profile_id ? profile_id : "null"
        }
        // const Homedata = await getAllHomeData(userObj)
        // setHomeData(Homedata?.data?.Language)
        // setHome({ ...Homedata, data: JSON.parse(Base64.decode(Homedata.data)) })

      }
    })();
  }, [location?.state])


  const handleLanguage = (data) => {
    history.push(ROUTES.moviesByLanguage, { id: data?.id })

  }
  return (
    <>
      <section style={{ paddingTop: "45px" }} className="m-profile setting-wrapper set-margin-responsive movie-main-div">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <h4 className="main-title mb-5 mt-5">Filtered by "{movies?.genre_title}"</h4>
          </div>

          <div className='dropdown-btn-genre drpdwn-colour'>
            <DropdownButton id="dropdown-item-button" title={'Filter by Language'}>
              <div style={{ maxHeight: '200px', overflowY: 'auto' }} className='remove-scroll'>
                {languageData?.map((data, index) => (
                  <Dropdown.Item key={index} style={{ cursor: "pointer", color: "white" }} as="button" onClick={() => handleLanguage(data)}>
                    {data.language_name}
                  </Dropdown.Item>
                ))
                }
              </div>
            </DropdownButton>
          </div>

        </div>
        <div className='movie-page-grid'>

          {
            movies?.data?.length ?
              movies?.data?.map((data, index) => (
                <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                  <div className='thumbnail-container'>
                    <img src={`${IMAGE_ENDPOINT + data?.movie_thumbnail}`} className="thumb-img1" alt={data?.movie_name} />
                    {/* {data?.movie_access == "TVOD" && <div className="block-icon-tvod-list">
                        <img src={rent_icon} width={"75px"} />
                      </div>} */}
                    <div className='hover-class1'>
                      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                        <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} />
                        <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                      </div>
                      <div style={{ color: "white", fontSize: "0.95rem" }}>
                        {data?.movie_name}
                      </div>
                      {/* <div style={{ color: "white", fontSize: "0.95rem" }}>
                        <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                        {"" + data?.movie_coin} Coins
                      </div> */}
                      <span style={{ color: "white", fontSize: "0.8rem", marginTop: "8px" }}>Duration : {data?.movie_duration}
                      </span>
                      <span style={{ color: "white", fontSize: "0.8rem", marginTop: "1px" }}> Gentre : {data?.movie_genre[0]?.genre_title}
                      </span>
                    </div>
                  </div>
                </Link>
              ))


              :
              <></>
          }
        </div>
        {
          !movies?.data?.length &&
          <div style={{ height: "45vh", width: "82vw" }} >
            <div className='mt-5' style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
              <img src={empty} style={{ height: "60px" }} />
              <p className='pt-4'> Sorry, No result found.</p>
              {/* <CircularProgress /> */}
            </div>
          </div>
        }

      </section>
    </>
  )
}
