import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import { gsap } from 'gsap'
import logo from '../../../assets/regal_images/regal_logo.png'
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as HomeActions from "../../../actions/HomeActions";
import { useDispatch } from 'react-redux';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { Avatar, Box, Card, Grid, Tooltip } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Share from '../common/Share';
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

// install Swiper modules
SwiperCore.use([Navigation]);


const DetailPage = () => {
    const playerRef = React.useRef(null);
    const [toggler1, setToggler1] = useState(false);
    const parameter = useParams();
    const [streamData, setStreamData] = useState("")
    const dispatch = useDispatch()
    const { getStreamDetails } = bindActionCreators(HomeActions, dispatch)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const [popupOpen, setPopupOpen] = useState(false)

    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const handlePopupClose = () => {
        setPopupOpen(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    useEffect(() => {
        (async () => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;

            if (parameter?.slug) {
                let userObj = {
                    user_id: null,
                    profile_id: null
                }
                const resData = await getStreamDetails(parameter?.slug, userObj)
                console.log(resData)
                setStreamData(resData)
            }

        })();
    }, [])




    const handlePlayNow = () => {
        // if (!isLoggedIn)
        //     setPopupOpen(true)
        // else
            history.push(ROUTES.stream, { stream: streamData })
    }
    return (
        <>
            <Modal
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} width={"100px"} />
                    </div>
                    <hr />

                    <div className="login-popup" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                            Login to continue
                        </Typography>
                        <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                            Login
                        </button>
                    </div>
                </Box>
            </Modal>
            <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                <div
                    id="home-slider"
                    className="slick-bg-1 m-0 p-0">

                    <div className='bg-video'>

                        <img src={IMAGE_ENDPOINT + streamData?.data?.poster} width={"100%"} />

                    </div>

                    <SwiperSlide className="slick-bg-2">
                        <Container fluid className="position-relative h-100">
                            <div className="slider-inner h-100">
                                <Row className="align-items-center iq-ltr-direction h-100 ">
                                    <Col xl="6" lg="12" md="12">
                                        <Link to="#">
                                            <div className="channel-logo pb-4" data-iq-delay="0.5">
                                                <h2>{streamData?.data?.channel_name}</h2>
                                                {/* <img src={IMAGE_ENDPOINT + streamData?.data?.thumbnail} className="c-logo" alt={streamData?.data?.channel_name} /> */}
                                            </div>
                                        </Link>

                                        <div className="d-flex flex-wrap align-items-center">
                                            <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" dangerouslySetInnerHTML={{ __html: streamData?.data?.description }}>
                                            </p>
                                        </div>

                                        <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                            <p>
                                                Streaming Date: <span>{streamData?.data?.streaming_date}</span>
                                            </p>




                                        </div>
                                        <div className="d-flex align-items-center r-mb-23 mt-2" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                                            <Button className="btn btn-hover iq-button" onClick={handlePlayNow}>
                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                            </Button>
                                            <Link to="/watchlist">
                                                <i className="fa fa-plus icon-border ml-2"></i>
                                            </Link>

                                            <div to="/movie-details" onClick={handleClick}>
                                                <i className="fa fa-share icon-border ml-2"></i>
                                            </div>

                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}

                                            >
                                                <Typography sx={{ p: 2 }}>
                                                    <Share sharedURL={window.location.href} />
                                                </Typography>
                                            </Popover>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Container>
                    </SwiperSlide>

                    {/* </video> */}
                </div>
            </section>



            {/* Related movie data */}

            {/* <div className='main-content'>
                <section id="iq-favorites">
                    <Container fluid>
                        <Row>
                            <Col sm="12" className="overflow-hidden">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="main-title" >Related {streamData?.data?.movie_category}</h4>
                                    <Link className="iq-view-all" to="/movie-category">View All</Link>
                                </div>
                                <div id="favorites-contens">
                                    <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                    <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div>
                                    <Swiper
                                        navigation={{
                                            prevEl: '#prev',
                                            nextEl: '#next'
                                        }}
                                        breakpoints={{
                                            320: { slidesPerView: 2 },
                                            450: { slidesPerView: 3 },
                                            638: { slidesPerView: 3 },
                                            810: { slidesPerView: 4 },
                                            // 991: { slidesPerView: 4 },
                                            1048: { slidesPerView: 5 },
                                            1300: { slidesPerView: 6 }
                                            // 1400: { slidesPerView: 4 },
                                            // 1800: { slidesPerView: 5 },
                                        }}
                                        loop={(streamData?.Related_Movie?.length > 4) ? true : false}
                                        slidesPerView={6}
                                        spaceBetween={0}
                                        as="ul"
                                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                        {
                                            streamData?.Related_Movie?.map((data, index) => (
                                                <SwiperSlide as="li" key={index}  >
                                                    

                                                    <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                                        <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className="thumb-img" alt={data?.movie_name} />
                                                    </Link>
                                                </SwiperSlide>
                                            ))
                                        }


                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div> */}
        </>
    )
}
export default DetailPage;