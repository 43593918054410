import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
// import SwiperCore, { Navigation } from 'swiper';
import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { CircularProgress, Grid, Popover, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Share from '../common/Share';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { gsap } from 'gsap'
import empty from '../../../assets/regal_images/empty-box (1).png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../constant/routes';
import { toast } from 'react-toastify';


// install Swiper modules
SwiperCore.use([Navigation]);
const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}
const Series = () => {
    const dispatch = useDispatch()
    const { getAllSeries } = bindActionCreators(MovieAction, dispatch)
    const [series, setSeries] = useState([])
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { removeFromWatchlist } = MovieAction
    const [save, setSave] = useState(false)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    useEffect(() => {
        (async () => {
            // if (profile_id) {
            let userObj = {
                profile_id: profile_id ? profile_id : null,
                user_id: user_id || "null"
            }
            const resData = await getAllSeries(userObj)
            setSeries(resData)
            console.log(resData)
            // }

        })();
    }, [save])

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleSliderClick = (data) => {
        history.push(ROUTES.seriesDetails, { series: data.series.id })
    }

    const addSeriesIntoWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                series: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }

    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: "",
                series_id: id
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }


    return (
        <>
            <section id="home" className="iq-main-slider p-0 iq-rtl-direction">
                {/* <div id="prev5" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next5" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                <Swiper
                    navigation={{
                        prevEl: '#prev5',
                        nextEl: '#next5'
                    }}

                    pagination={{
                        "clickable": true
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    autoplay={{
                        delay: 4000,
                    }}
                    onInit={() => { animationInit() }}
                    onSlideChangeTransitionStart={() => animationInit()}
                    loop={true}
                    id="home-slider"
                    className="slider m-0 p-0">
                    {
                        series?.data?.banner?.map((data, index) => (
                            <SwiperSlide
                                className="slide slick-bg"
                                key={index}
                                style={{ backgroundImage: `url(${IMAGE_ENDPOINT + data?.web_banner})` }}
                            // onClick={() => handleSliderClick(data)}
                            >
                                <Container fluid className="position-relative h-100">
                                    <div className="slider-inner h-100">
                                        <Row className="iq-ltr-direction h-100 ">
                                            <Col xl="6" lg="12" md="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                <div style={{ cursor: "pointer" }} className="channel-logo" data-iq-delay="0.5">
                                                    <Link to={`${ROUTES.seriesDetails}/${data?.series?.slug}`}>
                                                        {/* <img src={`${IMAGE_ENDPOINT + data?.series?.series_logo}`} className="c-logo" alt="streamit" /> */}
                                                        <h3 className="trending-text big-title text-uppercase mt-0 mb-2">{data?.series?.series_name}</h3>
                                                    </Link>
                                                </div>
                                                {/* <Link to={`${ROUTES.moviedetails}/${data?.movie?.slug}`}>
                                                    <h2 className="slider-text big-title title text-uppercase" data-iq-gsap="onStart" data-iq-position-x="-200">{data?.movie?.movie_name}</h2>
                                                </Link> */}
                                                <Link to={`${ROUTES.seriesDetails}/${data?.series?.slug}`}>
                                                    <div className="d-flex flex-wrap align-items-center" style={{ cursor: "pointer" }}>
                                                        <div className="d-flex flex-wrap align-items-center pt-3">
                                                            <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8" dangerouslySetInnerHTML={{ __html: data?.series?.series_description?.length <= 200 ? data?.series?.series_description : data?.series?.series_description?.substring(0, 200) + "..." }}>
                                                            </p>
                                                        </div>

                                                        <div className="trending-list" data-wp_object-in="fadeInUp" data-delay-in="1.2">
                                                            <p>
                                                                <b>Language: </b><span className='span-tag'>{data?.series?.language?.language_name}</span>
                                                            </p>
                                                            <p>
                                                                <b>Genres:</b> {data?.series?.genre?.map((data, index) => (<span className='span-tag' key={index}>{(index ? ', ' : '') + data.genre_title}</span>))}
                                                            </p>
                                                            {/* <p>
                                                            <b>Starring:</b> {data?.series?.movie_cast?.map((data, index) => (<span className='span-tag' key={index}>{(index ? ', ' : '') + data.cast_name}</span>))}
                                                        </p> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="d-flex align-items-center r-mb-23" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                                                    {/* <div className="btn btn-hover iq-button" onClick={() => handleSliderClick(data)}>
                                                        <i className="fa fa-play mr-2" aria-hidden="true"></i>Play Now
                                                    </div> */}
                                                    <Link to={`${ROUTES.seriesDetails}/${data?.series?.slug}`} className='icon-border-details ml-1' style={{ cursor: "pointer" }}>
                                                        <i className="fa fa-info details-con" aria-hidden="true"></i>  Details

                                                    </Link>
                                                    {/* {
                                                        data?.iswatchlist ?
                                                            <div onClick={() => RemoveWatchList(data?.movie?.id)} className='ml-3'>
                                                                <i class="fa fa-check icon-border" aria-hidden="true"></i>

                                                            </div>
                                                            :
                                                            <div onClick={() => AddWatchList(data?.movie?.id)} className='ml-3'>
                                                                <i className="fa fa-plus icon-border"></i>
                                                            </div>
                                                    } */}



                                                    <div onClick={(event) => handleClick(event)} style={{ cursor: "pointer" }}>
                                                        <i className="fa fa-share icon-border ml-2"></i>
                                                    </div>


                                                    {/* <div onClick={(event) => handleClick(event, data?.movie?.slug)}>
                                                        <i className="fa fa-share-alt share-icon ml-3" aria-hidden="true"></i>
                                                    </div> */}



                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={() => setAnchorEl(null)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>
                                                            <Share sharedURL={`${window.location.origin}/series-details/${data?.series?.slug}`} />
                                                        </Typography>
                                                    </Popover>

                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Container>
                            </SwiperSlide>
                        ))
                    }


                </Swiper>
            </section>
            <section className="main-content">
                <Container>
                    <h4 className=" mt-5">Web Series</h4>
                    <Grid container spacing={2}>
                        {
                            series?.data?.data?.length ?
                                series?.data?.data?.map((data, index) => (
                                    <Grid item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='mt-4 series-div-list'>
                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                            <img src={`${IMAGE_ENDPOINT + data?.series_poster}`} className="series-img" style={{ cursor: "pointer" }} alt={data?.series_name} />

                                        </Link>
                                        <div className='hover-class-series-list' style={{ width: "100%" }}>

                                            <div>
                                                <Grid container spacing={2} className='pb-2'>
                                                    <Grid item xs={4}>
                                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                            <img src={`${IMAGE_ENDPOINT + data?.series_thumbnail}`} className="hover-thumbnail" style={{ cursor: "pointer" }} />
                                                        </Link>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>

                                                            <p style={{ fontWeight: "bolder", cursor: "pointer" }}>{data?.series_name}</p>
                                                            <p style={{ marginTop: "-1rem", cursor: "pointer" }} dangerouslySetInnerHTML={{ __html: data?.series_description?.length <= 80 ? data?.series_description : data?.series_description?.substring(0, 80) + "..." }}></p>
                                                        </Link>
                                                        <div style={{ display: "flex", justifyContent: "end", fontSize: "30px", alignItems: "center" }}>
                                                            {
                                                                data?.iswatchlist ?
                                                                    <div style={{ cursor: "pointer" }} onClick={() => RemoveWatchList(data?.id)}>
                                                                        <i className="fa fa-check-square mr-2" aria-hidden="true"></i>
                                                                    </div>
                                                                    :
                                                                    <div onClick={() => addSeriesIntoWatchList(data?.id)} style={{ cursor: "pointer" }}>
                                                                        <i className="fa fa-plus-square mr-2" aria-hidden="true"></i>
                                                                    </div>


                                                            }
                                                            {/* <i className="fa fa-share-alt-square" aria-hidden="true"></i> */}

                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>

                                                <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", cursor: "pointer" }} className=''>
                                                    <div style={{ fontSize: "16px", margin: "0 auto", padding: "10px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                </div>
                                            </Link>
                                        </div>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className='mt-5 mb-5' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                        {/* <CircularProgress /> */}
                                    </div>
                                </Grid>
                        }

                    </Grid>

                </Container>
            </section>
        </>
    )
}
export default Series;