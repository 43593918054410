import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormControl from "@mui/material/FormControl";
import styles from "./form.module.css";
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { edit_user_details } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import Alert from 'react-bootstrap/Alert';
import emailImg from '../../../../assets/regal_images/mail.png'
import phoneImg from '../../../../assets/regal_images/telephone.png'
import DobImg from '../../../../assets/regal_images/birthday-cake.png'
import { ROUTES } from '../../../../constant/routes';

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Edit_Account = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))

    const dispatch = useDispatch()
    const [form, setForm] = useState({ ...user, userType: user?.userType?.id })
    let history = useHistory()
    const [show, setShow] = useState(false);

    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })
    const edit_user_details_message = useSelector((state) => state.home.edit_user_details_message);

    const [fieldFlag, setFieldFlag] = useState({ birthDate: false, firstName: false, lastName: false });


    const handleSubmit = (event) => {
        event.preventDefault();

        if (form?.firstName == undefined || form?.firstName == ""){
            setFieldFlag({ ...fieldFlag, email: false, firstName: true })


        }
        else if (form?.lastName == undefined || form?.lastName == ""){
            setFieldFlag({ ...fieldFlag, email: false, firstName: false, lastName: true })


        }
       
        else{
            dispatch(edit_user_details({ ...form }));

        }
        setTimeout(()=>{
            history.goBack()

        },1500)
    }
    const goBack = () => {
        history.push(ROUTES.my_account)
    }

    return (
        <>
            {/* <div className={`rtl-box ${show === true ? 'show' : ''}`} >
                <button type="button" className="btn btn-light rtl-btn">
                    <svg onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20" fill="white">
                        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                </button>
                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => { props.rtlModeAction('ltl') }} data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => { props.rtlModeAction('rtl') }} data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div> */}
            {/* <section className="sign-in-page"
                style={{ backgroundImage: "url(" + bg + ")" }}
            > */}
                <section className="sign-in-page"
                // style={{ backgroundImage: "url(" + bg + ")" }}
                style={{marginTop:"3rem"}}
            >
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <Form onSubmit={(event) => handleSubmit(event)}>
                                            <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Edit Account</p>

                                            <Row>
                                                <div className='edit-profile-icon' >
                                                    <Col md="4">
                                                        <div style={{ textAlign: "center" }}>
                                                            <Form.Label>
                                                                <img src={emailImg} alt='email' className='menu-img mb-1' />
                                                            </Form.Label><br />
                                                            <span>{form?.email}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md="4">
                                                        <div style={{ textAlign: "center" }}>
                                                            <Form.Label>
                                                                <img src={phoneImg} alt='email' className='menu-img mb-1' />
                                                            </Form.Label><br />
                                                            <span>{form?.mobileNumber}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md="4">
                                                        <div style={{ textAlign: "center" }}>
                                                            <Form.Label>
                                                                <img src={DobImg} alt='email' className='menu-img mb-1' />
                                                            </Form.Label><br />
                                                            <span>{new Date(form?.birthDate).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}</span>
                                                        </div>
                                                    </Col>
                                                </div>

                                                <Col md="12 mt-3">
                                                    <Form.Group>
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control type="text" isInvalid={fieldFlag.firstName} value={form?.firstName} onChange={(event) => (setForm({ ...form, firstName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="First Name" autoComplete="off" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter the First Name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md="12">
                                                    <Form.Group>
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control type="text" isInvalid={fieldFlag.lastName} value={form?.lastName} onChange={(event) => (setForm({ ...form, lastName: event.target.value }))} className="mb-0" id="exampleInputEmail3" placeholder="Last Name" autoComplete="off" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter the Last Name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                {/* <Col md="12">
                                                    <Form.Group>   
                                                        <Form.Label>Date of Birth</Form.Label>                              
                                                        <Form.Control type="date" isInvalid={fieldFlag.birthDate} value={form?.birthDate} onChange={(event)=>(setForm({...form,birthDate:event.target.value}))} className="mb-0" id="exampleInputPassword2" placeholder="Date of Birth" required/>
                                                        <Form.Control.Feedback type="invalid">
              Please enter the Date of Birth.
            </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col> */}

                                            </Row>

                                            {edit_user_details_message?.message && (edit_user_details_message?.statuscode == 200 ?
                                                <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                                    <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {edit_user_details_message?.message}
                                                    </p>
                                                </Alert>
                                                : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                    <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                        {edit_user_details_message?.message}
                                                    </p>
                                                </Alert>)}
                                            <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>

                                        </Form>
                                        <div style={{ cursor: "pointer" }} onClick={goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit_Account)