import React from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { bindActionCreators } from 'redux';
import { useState } from 'react';
import { Base64 } from 'js-base64';
import { getAllMovies } from '../actions/MovieAction';
import { getAllHomeData } from '../actions/HomeActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import ticket from '../assets/regal_images/ticket.png'
import { ROUTES } from '../constant/routes';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import nodatagif from '../assets/regal_images/no_data_found.gif'



const Vod = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const [movies, setMovies] = useState([])
    const [allMovies, setAllMovies] = useState([])
    const [tvod, setTvod] = useState([])


    const { getAllTvodMovie } = bindActionCreators(MovieAction, dispatch)

    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id


    useEffect(() => {
        (async () => {

            const resData = await getAllTvodMovie({ user_id: user_id || null })
            setTvod(resData?.data);
            console.log("resdata", resData)
            let user = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }
            // setLanguage(Homedata?.data?.Language)
            // setGenre(Homedata?.data?.Genre)

        })();
    }, [])





    console.log("tvod", tvod)

    return (
        <div className='vod-div'>
            <div className='vod-div-1'>
                {tvod?.length > 0 ? (
                    tvod?.map((movie, index) => (
                        <div key={index} className='vod-div-1_1'>
                            <div>
                                <div className='half-circle-left'></div>
                                <Link to={`${ROUTES.moviedetails}/${movie?.data?.slug}`}>
                                    <img className='vod-div-1-left-part shine' src={`${IMAGE_ENDPOINT + movie?.data?.movie_thumbnail}`} alt={movie?.data?.name} />
                                </Link>
                            </div>

                            <div className='vod-div-1-right-part'>
                                <div className='half-circle-right'></div>
                                <Link to={`${ROUTES.moviedetails}/${movie?.data?.slug}`}>

                                    <h5 >{movie?.data?.movie_name}</h5>
                                    <span >Duration : {movie?.data?.movie_duration}</span>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>Genre :</span>
                                        <h6>{movie.data?.movie_genre[0].genre_title}</h6>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>Validity :</span>
                                        <h6> {movie?.data?.available_for_days} Days</h6>
                                    </div>
                                    <p style={{ fontSize: "15px", margin: "0" }} dangerouslySetInnerHTML={{ __html: movie?.data?.movie_description?.length <= 60 ? movie?.data?.movie_description : movie?.data?.movie_description?.substring(0, 70) + "..." }}></p>


                                </Link>
                                {movie?.is_rental_expiry ? (<button onClick={() => history.push("/price-plan",
                                    {
                                        transactionType: "TVOD", redirect: ROUTES.moviedetails + '/' + movie?.data?.slug,
                                        available_for: movie?.data?.available_for_days,
                                        movieName: movie?.data?.movie_name, movie: movie?.data?.id,
                                        categoryType: "Movie", price: movie?.data?.rental_price_with_ad,
                                        adfreePrice: movie?.data?.rental_price, ads: movie?.advertise_data
                                    }
                                )}
                                    style={{ marginTop: "auto" }}>
                                    <img style={{ height: "30px", width: "32px", marginRight: "15px" }} src={ticket} alt="" />
                                    Rent Now $ {Number(movie?.data?.rental_price_with_ad).toFixed(2)}
                                </button>)

                                    : (
                                        <Link style={{ marginTop: "auto", width: "100%" }} to={`${ROUTES.moviedetails}/${movie?.data?.slug}`}>
                                            <button style={{ marginTop: "auto", width: "100%", display: "flex", gap: "7px", alignItems: "center", justifyContent: "center" }}>
                                                <PlayCircleOutlineIcon style={{ color: "#ba000b" }} />
                                                Play
                                            </button>
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ height: "48vh", width: "82vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {/* <img height={190} width={190} src={nodatagif} alt="" /> */}
                        <h4>No Video Found</h4>
                    </div>
                )}




            </div>
        </div>
    );
}
export default Vod;