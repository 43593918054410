import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Pricing from '../views/backend/main/pricing'

//blog
import Blog from '../views/backend/blog/blog';
import BlogDetail from '../views/backend/blog/blog-details';

//App
import UserProfile from '../views/backend/app/usermanagement/userprofile'
import UserAccountSettingList from '../views/backend/app/usermanagement/useraccountsetting'

//Extrapages
import FAQ from '../views/backend/pages/faq'
import TermsOfUse from '../views/backend/pages/extrapages/termsOfUse'
import PrivacyPolicy from '../views/backend/pages/extrapages/privacyPolicy'
import AboutUs from '../views/backend/pages/extrapages/aboutUs'
import Contact from '../views/backend/pages/contact'
import PricingPlan2 from '../views/backend/pages/pricing/pricing2';

//Category
import PremiumCategory from '../views/backend/category/PremiumCategory';

//Movie
import AddMovie from '../views/backend/movie/add-movie';
import MovieList from '../views/backend/movie/movie-list';

//Show
import ShowList from '../views/backend/show/show-list';

// Home
import Homepage from '../views/backend/home/home'
import Series from '../views/backend/series/Series';
import LiveTV from '../views/backend/live-tv/LiveTV';
import LiveStreaming from '../views/backend/live-tv/LiveStreaming';
import DetailPage from '../views/backend/live-tv/DetailPage';
import { ROUTES } from '../constant/routes';
import MovieDetailsPage from '../views/backend/movie/MovieDetailsPage';
import signup from '../views/backend/pages/auth/signup';
import profile from '../views/backend/pages/auth/profile';
import profile_edit from '../views/backend/pages/auth/profile_edit';

import complaint from '../views/backend/pages/auth/complaint';

import Wishlist from '../views/backend/watchlist/WatchList';
import WatchList from '../views/backend/watchlist/WatchList';
import SeriesDetails from '../views/backend/series/SeriesDetails';
import RefundPolicy from '../views/backend/pages/extrapages/refundPolicy';
import MovieByLanguage from '../views/backend/movie/moviebylanguage/MovieByLanguage';
import MoviebyGenre from '../views/backend/movie/moviebyGenre/MoviebyGenre';
import my_account from '../views/backend/pages/auth/my_account';
import device_management from '../views/backend/pages/auth/device_management';
import edit_account from '../views/backend/pages/auth/edit_account';
import transaction_history from '../views/backend/pages/auth/transaction_history';
import change_password from '../views/backend/pages/auth/change_password';
import ShortFilms from '../views/backend/movie/shortfilms/ShortFilms';
import forgot_password from '../views/backend/pages/auth/forgot_password';
import Logout_Otp from '../views/backend/pages/auth/Logout_Otp';
import Search1 from '../components/Search1';
import Rewards from '../components/Rewards';
import Vod from '../components/Vod';
import StripePayment from '../razorpay/StripePayment';
import Qr_detail from '../components/Qr_detail';
import Contact_us from '../views/backend/pages/contact';
import Qr_layout from '../components/Qr_layout';
import Upcomingmovies from '../components/Upcomingmovies';
import MovieDemandForm from '../views/backend/pages/MovieDemandForm';


const Layout1Route = () => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                // key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>

                    <Route path={ROUTES.priceplan} component={PricingPlan2} />
                    <Route path={ROUTES.demandMovie} component={MovieDemandForm} />

                    <Route path={ROUTES.search}  component={Search1} />
                    <Route path={ROUTES.rewards} component={Rewards} />
                    <Route path={ROUTES.qrdetail} component={Qr_layout} />
                    <Route path={ROUTES.vod} component={Vod} />
                    <Route path={ROUTES.shortSeries} component={ShortFilms} />
                    <Route path={ROUTES.contactus} component={Contact} />

                    {/* App */}
                    <Route path="/manage-profile" component={UserProfile} />
                    <Route path={ROUTES.stripePayment} component={StripePayment} />
                    <Route path="/setting" component={UserAccountSettingList} />

                    {/* Blog */}
                    <Route path="/blog" component={Blog} />
                    <Route path="/blog-details" component={BlogDetail} />

                    {/* Extrapages */}
                    <Route path="/faq" component={FAQ} />
                    <Route path="/terms-of-service" component={TermsOfUse} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/refund-policy" component={RefundPolicy} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path={ROUTES.contact} component={Contact} />
                    <Route path="/pricing-plan-2" component={Pricing} />

                    {/* Category */}
                    <Route path={ROUTES.shortfilms} component={ShortFilms} />

                    {/* Movie */}
                    <Route path={`${ROUTES.moviedetails}/:slug`} component={MovieDetailsPage} />
                    <Route path={ROUTES.movies} component={MovieList} />
                    <Route path={ROUTES.moviesByLanguage} component={MovieByLanguage} />
                    <Route path={ROUTES.moviesByGenre} component={MoviebyGenre} />

                    {/* Trailer */}


                    {/* Series */}
                    <Route path={ROUTES.series} component={Series} />
                    <Route path={`${ROUTES.seriesDetails}/:slug`} component={SeriesDetails} />


                    {/*  */}
                    <Route path="/live-tv" component={LiveTV} />
                    <Route path="/live-streaming" component={LiveStreaming} />
                    <Route path={`/live-streaming-details/:slug`} component={DetailPage} />



                    {/* Show */}
                    <Route path="/show-details" component={ShowList} />

                    {/* homepage */}
                    <Route path={ROUTES.home} exact component={Homepage} />

                    {/* Login process */}
                    <Route path={ROUTES.signup} component={signup} />
                    {/* Profile process */}
                    <Route path={ROUTES.profile_edit} component={profile_edit} />

                    {/* Complaint process */}
                    <Route path={ROUTES.complaint} component={complaint} />
                    {/* My Account */}
                    <Route path={ROUTES.my_account} component={my_account} />
                    {/* My Account */}
                    <Route path={ROUTES.device_management} component={device_management} />
                    {/* Edit Account */}
                    <Route path={ROUTES.edit_account} component={edit_account} />
                    {/* Edit Account */}
                    <Route path={ROUTES.change_password} component={change_password} />
                    {/* forgot_password */}
                    <Route path={ROUTES.forgot_password} component={forgot_password} />
                    {/* Transactions  */}
                    <Route path={ROUTES.transaction_history} component={transaction_history} />

                    {/* Wishlist */}
                    <Route path={ROUTES.watchlist} component={WatchList} />
                    {/* Logout all devices otp */}
                    <Route path={ROUTES.logoutwithOTP} component={Logout_Otp} />

                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route